import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isLoggedIn } from 'bmc-account-next/lib/js/server/helpers/user';
import { UserDataContext } from './userdata-context';

const mapStateToProps = (state) => ({
  userData: state.panel.userData,
  resolvedCountry: state.panel.resolvedCountry
});

class UserDataProvider extends Component {
  static propTypes = {
    userData: PropTypes.object,
    resolvedCountry: PropTypes.string
  }

  render() {
    var userData = this.props.userData;

    if(!isLoggedIn(userData)) {
      userData = {
        country: this.props.resolvedCountry
      }
    }

    // The entire state is passed to the provider
    return (
      <UserDataContext.Provider value={ userData }>
        { this.props.children }
      </UserDataContext.Provider>
    );
  }
}

export default connect(mapStateToProps)(UserDataProvider);
