import React, { Component } from 'react'
import PropTypes from 'prop-types';
import AOS from 'aos';
import Helmet from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby'
import { connect } from 'react-redux';

import Footer from 'bmc-account-next/lib/js/components/parts/Footer'

import '../sass/common.sass';
import 'bmc-account-next/components/tooltips/VerifyTooltip.sass'
import mixpanelService from 'bmc-account-next/lib/js/services/mixpanel';
import socialIcons from '../components/nav/socials';

import { initConfig } from 'bmc-account-next/lib/js/services/appConfig';
import { getUserData } from 'bmc-account-next/lib/js/redux/panel/actions/panel';
import { isLoggedIn } from 'bmc-account-next/lib/js/server/helpers/user';
import { externalLinks } from '../helpers/externalLinks';
import ErrorBoundary from './ErrorBoundary';

import InternalLink from './nav/link';

initConfig(function(property) {
  switch (property) {
    case 'RECAPTCHA_V2_KEY': return process.env.RECAPTCHA_V2_KEY;
    case 'RECAPTCHA_V3_KEY': return process.env.RECAPTCHA_V3_KEY;
    case 'SLACK_COMMUNITY_INVITE_ID': return process.env.SLACK_COMMUNITY_INVITE_ID;
    case 'SOCKET_URL': return process.env.SOCKET_URL;
    case 'isRecaptchaDisabled': return process.env.CRYPTO_RECAPTCHA_CHECK_DISABLED === 'true';
    default: throw new Error('access to unknown propery: ' + property );
  }
});

const isProduction = process.env.NODE_ENV === 'production';

const mapStateToProps = (state, ownProps) => ({
  userData: state.panel.userData,
  resolvedCountry: state.panel.resolvedCountry,
})

const mapDispatchToProps = (dispatch) => ({
  getUserData: (param) => {
    dispatch(getUserData(param));
  },
});

class Layout extends Component {
  static propTypes = {
    getUserData: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    disableFooter: PropTypes.bool,
    afterMetaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    metaKeywords: PropTypes.string,
    location: PropTypes.object,
  }

  static defaultProps = {
    afterMetaTitle: "Crypto Trading Platform",
    metaDescription: "Trade bitcoin, altcoins and tokenized assets on a secure platform.",
    metaKeywords: "crypto trading, buy bitcoin with credit card, bitcoin trading, Binance, Bitfinex, BMC, ethereum price, secure crypto trading blockchain, Gram, Telegram Open Network"
  }

  getChildContext() {
    return {
      LinkComponent: InternalLink,
    };
  }

  static childContextTypes = {
    LinkComponent: PropTypes.func,
  }

  componentDidMount() {
    AOS.init({
      duration: 600,
      once: true,
      offset: 50,
    });

    // ignore the rest
    return;

    this.props.getUserData();

    switch (this.props.location.pathname) {
      case '/':
        mixpanelService.get().track('Page view', { page: 'Main page' });
        break;
      case '/crypto':
        mixpanelService.get().track('Page view', { page: 'Sets & Strategies page' });
        break;
      default:
        break;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userData !== prevProps.userData) {
      const id = this.props.userData ? this.props.userData.id : null;

      if (id) {
        mixpanelService.get().identify(id);
      }
    }
  }

  render() {
    const mixpanelInit = `window.mixpanel.init("!!!MIXPANEL_ID!!!",{debug: ${ isProduction ? 'false' : 'true' }});`;

    return (
      <StaticQuery
        query={ graphql`
          query LayoutQuery {
            site {
              siteMetadata {
                socials {
                  telegram
                  reddit
                  slack
                  twitter
                  facebook
                  github
                  medium
                  youtube
                }
              }
            }
          }
        `}
        render={ data => (
          <div className="page">
            <Helmet
            >
              <meta charSet="utf-8" />
              <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <title>{ this.props.title + " | Blackmoon" }</title>
              <meta name="theme-color" content="#005CB9" />
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=ngkjoveyzy" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=ngkjoveyzy" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=ngkjoveyzy" />
              <link rel="manifest" href="/manifest.json?v=ngkjoveyzy" />
              <link rel="mask-icon" href="/safari-pinned-tab.svg?v=ngkjoveyzy" color="#000000" />
              <link rel="shortcut icon" href="/favicon.ico?v=ngkjoveyzy" />
              <meta name="apple-mobile-web-app-title" content="Blackmoon" />
              <meta name="application-name" content="Blackmoon" />
              <meta name="title" content={ this.props.title } />
              <meta name="description" content={ this.props.metaDescription } />
              {
                this.props.metaKeywords &&
                <meta name="keywords" content={ this.props.metaKeywords }></meta>
              }

              <meta property="og:type" content="website" />
              <meta property="og:site_name" content="Crypto Exchange | Blackmoon" />
              <meta property="og:title" content={ this.props.title + " | Blackmoon" } />
              <meta property="og:description" content={ this.props.metaDescription } />
              <meta property="og:url" content="https://blackmoon.net/" />
              <meta property="og:locale" content="en_US" />
              <meta property="og:image" content="https://blackmoon.net/i/preview.jpg" />
              <meta property="og:image:width" content="980" />
              <meta property="og:image:height" content="520" />
              <meta name="twitter:card" content="summary_large_image" />
              {/*<meta name="twitter:title" content={ this.props.title + " | Blackmoon" } />*/}
              {/*<meta name="twitter:description" content={ this.props.metaDescription } />*/}
              {/*<meta name="twitter:image:src" content="https://blackmoon.net/i/preview.jpg" />*/}
              {/*<meta name="twitter:url" content="https://blackmoon.net/" />*/}
              {/*<meta name="twitter:domain" content="blackmoon.net" />*/}
              {/*<meta name="twitter:site" content="@BlackmoonFG" />*/}

              <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700&amp;amp;subset=cyrillic" rel="stylesheet" />
              <link href="https://fonts.googleapis.com/css?family=PT+Sans:400,700&amp;amp;subset=cyrillic" rel="stylesheet" />

              {/*<script*/}
              {/*  src="/static/next/mixpanel-jslib-snippet.min.js"*/}
              {/*  onLoad={ !isProduction && mixpanelInit }*/}
              {/*></script>*/}
            </Helmet>
            <ErrorBoundary>
              { this.props.children }
              { !this.props.disableFooter &&
                <Footer
                  socials={ data.site.siteMetadata.socials }
                  location={ this.props.location }
                  LinkComponent={ InternalLink }
                  icons={ socialIcons }
                  externalLinks={ externalLinks }
                />
              }
            </ErrorBoundary>
          </div>
        ) }
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
// export default Layout;
