import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as InternalLink } from 'gatsby';
import routes from 'bmc-account-next/server/routesMap.json';

export default class Link extends Component {
  static propTypes = {
    route: PropTypes.string.isRequired,
    isExternal: PropTypes.bool,
    activeClassName: PropTypes.string,
    params: PropTypes.object,
  }

  prepareLinkFromRoutes(route, params) {
    let result = routes[route];

    for (let key in params) {
      result = result.replace(`:${ key }`, params[key]);
    }

    return result;
  }

  render() {
    const { route, isExternal, activeClassName, params, ...restProps } = this.props;

    let link = route;

    if (params) {
      link = this.prepareLinkFromRoutes(route, params);
    }

    if (params || isExternal) {
      return <a href={ link } { ...restProps } />;
    }

    return (
      <InternalLink to={ link } { ...restProps } activeClassName={ activeClassName } />
    );
  }
}
