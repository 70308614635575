"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isLoggedIn = exports.isRestrictedForMaltaPerson = exports.isMaltaPerson = exports.isChinaPerson = exports.isRestrictedPerson = void 0;

var _countries = require("../services/countries");

var _assets = require("./assets");

var isRestrictedPerson = function isRestrictedPerson(userData) {
  if (!userData) {
    return false;
  }

  var country = userData.country;
  return _countries.DENIED_COUNTRY_NAME_BY_ISO[country] !== undefined;
};

exports.isRestrictedPerson = isRestrictedPerson;

var isChinaPerson = function isChinaPerson(userData) {
  if (!userData) {
    return false;
  }

  var country = userData.country;
  return country === 'CN';
};

exports.isChinaPerson = isChinaPerson;

var isMaltaPerson = function isMaltaPerson(userData) {
  if (!userData) {
    return false;
  }

  var country = userData.country;
  return country === 'MT';
};

exports.isMaltaPerson = isMaltaPerson;

var isRestrictedForMaltaPerson = function isRestrictedForMaltaPerson(userData, asset, country) {
  var userCountry = country;
  var isLoggedIn = !!userData;

  if (isLoggedIn) {
    return userCountry = userData.country;
  }

  var restrictedAsset = _assets.FIAT_ASSETS.includes(asset);

  return restrictedAsset && userCountry === 'MT';
};

exports.isRestrictedForMaltaPerson = isRestrictedForMaltaPerson;

var isLoggedIn = function isLoggedIn(userData) {
  return userData && !!userData.id;
};

exports.isLoggedIn = isLoggedIn;