import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import InternalLink from './nav/link';
import navIcons from './nav/icons';

import BmcNav from 'bmc-account-next/lib/js/components/parts/nav/Nav';
import { isLoggedIn } from 'bmc-account-next/lib/js/server/helpers/user';

import { externalLinks } from '../helpers/externalLinks';

const mapStateToProps = (state) => ({
  userData: state.panel.userData,
  resolvedCountry: state.panel.resolvedCountry,
});

class Nav extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    userData: PropTypes.object,
    isTransparent: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool,
    withBackground: PropTypes.bool.isRequired,
    resolvedCountry: PropTypes.string,
  }

  static defaultProps = {
    isTransparent: true,
    withBackground: true,
  }

  render() {
    var userData = this.props.userData;

    if(!isLoggedIn(userData)) {
      userData = {
        country: this.props.resolvedCountry
      }
    }

    return (<BmcNav
      navIcons={ navIcons }
      location={ this.props.location }
      isLoggedIn={ this.props.isLoggedIn ? this.props.isLoggedIn : isLoggedIn(userData) }
      userData={ userData }
      LinkComponent={ InternalLink }
      withBackground={ this.props.withBackground }
      isTransparent={ this.props.isTransparent }
      externalLinks={ externalLinks }
    />);
  }
}

export default connect(mapStateToProps)(Nav);
