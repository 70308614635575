import React from 'react';

import { ReactComponent as FacebookSvg } from '../../../src/i/sprites/common/soc/facebook.svg';
import { ReactComponent as GithubSvg } from '../../../src/i/sprites/common/soc/github.svg';
import { ReactComponent as MediumSvg } from '../../../src/i/sprites/common/soc/medium.svg';
import { ReactComponent as InstagramSvg } from '../../../src/i/sprites/common/soc/instagram.svg';
import { ReactComponent as RedditSvg } from '../../../src/i/sprites/common/soc/reddit.svg';
import { ReactComponent as SlackSvg } from '../../../src/i/sprites/common/soc/slack.svg';
import { ReactComponent as TelegramSvg } from '../../../src/i/sprites/common/soc/telegram.svg';
import { ReactComponent as TwitterSvg } from '../../../src/i/sprites/common/soc/twitter.svg';
import { ReactComponent as YoutubeSvg } from '../../../src/i/sprites/common/soc/youtube.svg';
import { ReactComponent as BmcFooterSvg } from '../../../src/i/sprites/common/bmc_footer.svg'

export default {
  facebookSvg: <FacebookSvg className="footer__soc-i" />,
  githubSvg: <GithubSvg className="footer__soc-i" />,
  mediumSvg: <MediumSvg className="footer__soc-i" />,
  instagramSvg: <InstagramSvg className="footer__soc-i" />,
  redditSvg: <RedditSvg className="footer__soc-i" />,
  slackSvg: <SlackSvg className="footer__soc-i" />,
  telegramSvg: <TelegramSvg className="footer__soc-i" />,
  twitterSvg: <TwitterSvg className="footer__soc-i" />,
  youtubeSvg: <YoutubeSvg className="footer__soc-i" />,
  platformSvg: <BmcFooterSvg className="footer__logo-i" />,
};
