import React from 'react';

import { ReactComponent as BmcHeaderSvg } from '../../i/sprites/common/blackmoon-hor.svg'
import { ReactComponent as InvestProductsSvg } from '../../i/sprites/common/nav/invest-products.svg'
import { ReactComponent as AboutPlatformSvg } from '../../i/sprites/common/nav/about-platform.svg'
import { ReactComponent as AdvantagesForInvestorsSvg } from '../../i/sprites/common/nav/advantages-for-investors.svg'
import { ReactComponent as ApolloSvg } from '../../i/sprites/common/nav/apollo.svg'
import { ReactComponent as RoadmapSvg } from '../../i/sprites/common/nav/roadmap.svg'
import { ReactComponent as AboutSvg } from '../../i/sprites/common/nav/about.svg'
import { ReactComponent as ContanctsSvg } from '../../i/sprites/common/nav/contacts.svg'
import { ReactComponent as BlogSvg } from '../../i/sprites/common/nav/blog.svg'
import { ReactComponent as AskQuestionSvg } from '../../i/sprites/common/nav/ask-question.svg'
import { ReactComponent as FaqSvg } from '../../i/sprites/common/nav/faq.svg'
import { ReactComponent as LegalDocsSvg } from '../../i/sprites/common/nav/legal-docs.svg'
import { ReactComponent as EquitySvg } from '../../i/sprites/common/nav/equity.svg'
import { ReactComponent as CloseSvg } from '../../i/sprites/common/close.svg'
import { ReactComponent as CryptoSvg } from '../../../static/img/set.svg'
import { ReactComponent as FiatSvg } from '../../../static/img/products.svg'
import { ReactComponent as ExchangeSvg } from '../../../static/img/exchange.svg'

export default {
  bmcHeaderSvg: <BmcHeaderSvg className="nav__logo-i" />,
  investProductsSvg: <InvestProductsSvg className="nav__submenu-i" />,
  fiatSvg: <FiatSvg className="nav__submenu-i" />,
  cryptoSvg: <CryptoSvg className="nav__submenu-i" />,
  aboutPlatformSvg: <AboutPlatformSvg className="nav__submenu-i" />,
  advantagesForInvestorsSvg: <AdvantagesForInvestorsSvg className="nav__submenu-i" />,
  apolloSvg: <ApolloSvg className="nav__submenu-i" />,
  roadmapSvg: <RoadmapSvg className="nav__submenu-i" />,
  aboutSvg: <AboutSvg className="nav__submenu-i" />,
  contanctsSvg: <ContanctsSvg className="nav__submenu-i" />,
  blogSvg: <BlogSvg className="nav__submenu-i" />,
  askQuestionSvg: <AskQuestionSvg className="nav__submenu-i" />,
  faqSvg: <FaqSvg className="nav__submenu-i" />,
  legalDocsSvg: <LegalDocsSvg className="nav__submenu-i" />,
  exchangeSvg: <ExchangeSvg className="nav__submenu-i" />,
  equitySvg: <EquitySvg src={ EquitySvg } className="nav__submenu-i" />,
  closeSvg: <CloseSvg className="nav__close-i" />,
};
